<template>
    <div class="warning-object-set-base">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <router-view class="warning-object-set-view"></router-view>
    </div>
</template>
<script>
export default {
    name: "warning_object_set",
    data() {
        return {
            itemName: "预警对象设置",
            breadlist: [
                {
                    path: "",
                    name: "业务管理"
                }
            ]
        };
    }
};
</script>
<style lang="scss" scoped>
.warning-object-set-base {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    .warning-object-set-view {
        width: 100%;
        height: calc(100% - 45px);
    }
}
</style>